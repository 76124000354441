import React from 'react'

import './style.css'

const pages = [
  ['', 'home']
, ['About', 'about']
, ['Resume', 'resumé']
]

const navigateTo =
        destination =>
          () => {
            window.scrollTo(0,0)
            window.location.href = destination
          }

const MenuItem =
        ({location, name}) => {
          if (name === 'resumé') return <a className="menuitem" target="_blank" href="KEB_Resume_2019.pdf">{name}</a>

          const current = window.location.href.split('#')[1] || ''
          const className = "menuitem" + ( (current === location) ? " current" : "")

          return (
            <span className={className} onClick={navigateTo('#' + location)}>{name}</span>
          )
        }

const NextItemButton =
        ({nextItem, previousItem, onClickNext}) => (
          <div className="navigator">
            <div onClick={onClickNext(previousItem)} className="previtem">
              <img alt="next item arrow" src="images/arrows-RIGHT.svg" />
              <div className="navtext">
                back
              </div>
            </div>

            <div onClick={onClickNext(nextItem)} className="nextitem">
              <div className="navtext">
              next
              </div>
              <img alt="next item arrow" src="images/arrows-RIGHT.svg" />
            </div>
          </div>
        )

const Menubar =
        ({nextItem, onClickNext, previousItem}) => {
            const className =
                    nextItem
                    ? 'menu withnav'
                    : 'menu'
                return (
                        <div className="menubar">
                          <div className="menubox">
                            <div className={className}>
                              { pages.map(
                                  ([location, name]) => <MenuItem name={name} key={name} location={location} />
                                )
                              }
                            </div>
                            { nextItem
                              ? <NextItemButton nextItem={nextItem} previousItem={previousItem} onClickNext={onClickNext}/>
                              : null
                            }
                          </div>
                        </div>

                      )
              }

export default Menubar
