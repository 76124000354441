export default {
        route: `vegan_please`
      , image:  `media/VeganPlease/CorrectSizes_VeganPlease-01.jpg`
      , title: `Vegan Please`
      , headlineA: `when`
      , headlineB: `in`
      , headlineC: `roam`
      , text: `
      <p>
        Vegan Please is an award-winning app that translates the dietary needs of vegans into multiple languages. I worked with native speakers to ensure the translations were not only accurate, but also featured nuances found in fluency. This app was originally designed in 2011, when it won a Design for Social Good award. It has recently been refreshed (the update reflected in the images presented here), and soon will be implemented into iOS and Android platforms.
      </p>
      <p>
        <em>
          Role: UI design, product design, creative direction, content strategy, copywriting.
        </em>
      </p>`
      , credits: `
          My role included UI and digital marketing design
  `
      , images: [
          {
            title: `Product Page`
          , image:  `media/VeganPlease/CorrectSizes_VeganPlease-01.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/VeganPlease/CorrectSizes_VeganPlease-02.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/VeganPlease/CorrectSizes_VeganPlease-03.jpg`
          }
        ]
      }
