export default {
      route: `sweet-habit`
    , video: `/media/SweetHabit/2_Website.mp4`
    , type: `Brand System`
    , headlineA: `march`
    , headlineB: `of the`
    , headlineC: `penguins`
    , text: `
    <p>
    Sweet Habit was a new ice cream brand aimed at the low-cal audience. Ice cream equals…penguins? And thus the penguin world was born, with custom feathers for each flavor, bright colors for differentiation, and sassy copy to keep it cool.   </p>
    <p>
    <em>
    Role: strategy, copywriting, packaging design, UI, social media marketing, design direction
    </em>
    </p>
`
    , credits: `
    SD Yours Truly<br />
`
    , images: [
        {
          title: `Packaging PDPs`
        , image: `/media/SweetHabit/1_SweetHabitLogo.jpg`
        }
      , {
          title: `Packaging PDPs`
        , video: `/media/SweetHabit/2_Website.mp4`
        }
      , {
          title: `Packaging PDPs`
        , image: `/media/SweetHabit/3_Lineup.jpg`
        }
      , {
          title: `Full Flock`
        , video: `/media/SweetHabit/4_Sweet Habit Social Media.mp4`
        , quote: `
        Georgia Thunes (EVP, Creative Strategy, Sterling Brands) “Having worked with Kate on a wide range of brand design projects, I can honestly say she’s a strategist’s best friend: thoughtful, inventive, and she champions high-level design thinking.”
        `
        }
      , {
          title: `Website`
        , image: `/media/SweetHabit/5_Collection.jpg`
        }
      , {
          title: `Social Media Marketing`
        , image: `/media/SweetHabit/6_2Up.jpg`
        }

      , {
          title: `Promotional Swag`
        , image: `/media/SweetHabit/7_Swag.jpg`
        , text: `
        <p>
          The penguin world grows with each extension, demonstrating a larger brand system toolkit that can be applied to a variety of avenues.
        </p>
`
        }
      ]
    }
