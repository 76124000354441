import React from 'react'
import Menubar from './components/Menubar'

const Resume = ({data, transitionFrom}) => (
  <div className="page">
    <div className="pagecontent heropagecontent">
      <Menubar/>
      <div className="abovefold">
        <div className="hero">
          <div className="info-text-column">
            <h2 className="info-page-header">
            I might have given this to you already, but in case my brain fell out of my ear, here it is.
          </h2>
          <div className="separator" />
            <p>
              <a href="/KEB_Resume_2019.pdf">KEB_Resume_2019.pdf</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  )

export default Resume
