import spoonful_one from './spoonful_one'
import sweet_habit from './sweet_habit'
import lab126 from './lab126'
// import wd from './wd'
// import gtech from './gtech'
// import walmart from './Walmart'
import vegan_please from './vegan_please'
import roots_run_deep from './roots_run_deep'
import previous_work from './previous_work'
import side_project from './side_project'

export default [
    // wd
  // , walmart
  , spoonful_one
  , sweet_habit
  , lab126
  // , gtech
  , vegan_please
  , roots_run_deep
  , previous_work
  , side_project
]
