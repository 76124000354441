import React, { useState} from 'react'
import Transition from 'react-transition-group/Transition';

import Menubar from '../components/Menubar'
import ItemDetail from '../components/ItemDetail'
import Tail from '../components/Tail'

const ItemPage =
    (props) => {

      const [showNext, setShowNext] = useState(false)
      const [itemNext, setItemNext] = useState(false)
      const [showTransition, setShowTransition] = useState(true)

      const { data, route } = props
      const idx = data.findIndex(datum => datum.route === route)
      const datum = data[idx]
      const nextItem = data[idx+1] || data[0]
      const previousItem = data[idx-1] || data[data.length-1]

      const onClick =
          () => {
            window.location.href  = '#'+route+"_"+window.scrollY+"_"}

      const onClickNext =
          nextItem =>
            () => {
            setItemNext(nextItem)
            setShowTransition(true)
            setShowNext(true)
            setTimeout(
              () => {
                // history.pushState({},"Title","https://stackoverflow.com/example")
                window.location.href = '#'+nextItem.route
                window.scrollTo(0,0)
                setShowTransition(false)
                setShowNext(false)
                setShowTransition(true)
              }
            , 1000
            )
          }

      return (
          <div className="content" >
            <div className="page">
              <div className="height-based-constrainer">
                {
                  Boolean(showTransition)
                  ? (
                    <Transition
                      in={showNext}
                      timeout={0}
                    >
                      {state => (
                          <div className={'detail-holder detail-show '+state}>
                            <div className="detail-overlay">
                              <div className="page" >
                                <div className="height-based-constrainer">
                                  <div className="overlay" >
                                  { itemNext
                                    ? <ItemDetail {...itemNext} />
                                    : null
                                  }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </Transition>

                  )
                  : null
                }
                <ItemDetail
                  onClick={onClick}
                  {...datum}
                />
                <Tail />
                <Menubar nextItem={nextItem} previousItem={previousItem} onClickNext={onClickNext} />
              </div>
            </div>
        </div>
      )
    }

export default ItemPage
