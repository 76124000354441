import React from 'react'
// Scroll by a component's ref
import { ScrollTo } from "react-scroll-to";
import './style.css'
import '../style.css'

const NavArrowUp =
        ({scrollRef
        }) => (
          <ScrollTo>
            {({ scroll }) => {
              const onClick = () => {
                console.log('cluck')
                scroll({ x: 0, y: 0,  smooth: true })
              }
              return (
              <div
                className="more-arrow nav-arrow-up"
                onClick={onClick}
                >
                <div className="more-arrow-holder">
                  <img className="more-arrow-yellow" alt="more-arrow" src="images/arrows-UP-YELLOW.svg" />
                  <img className="more-arrow-red" alt="more-arrow" src="images/arrows-UP.svg" />
                </div>
              </div>
            )}}
          </ScrollTo>
        )

export default NavArrowUp
