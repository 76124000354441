import React, { Component } from 'react'
import ScrollManager from './helpers/ScrollHelper'

import './style/index.css'
import './style/quotes.css'

import Menu from './components/Menubar'
import Project from './components/Item'
import Email from './components/Email'
import PageDecorations from './components/PageDecorations'

import projectData from '../data'

// Scroll by a component's ref
import NavArrowDown  from './components/NavArrow/Down';
import Tail from './components/Tail';

const HomepageContent =
        ({data
        , transitionFrom
          }) => (
            <div className="page">
              <ScrollManager scrollKey="Homepage" />
                  <div className="abovefold">
                    <div className="hero">
                      <div className="headerbox">
                        <h1>
                          <em>
                            Hi.
                          </em>
                          <br />
                          I'm Kate
                          <br />
                          El-Bizri.
                        </h1>
                        <NavArrowDown />
                      </div>
                        <div className="description">
                          <p>
                            I’m a cross-disciplinary brand designer.
                            <br />
                            I do digital, print, leadership, strategy, copywriting, design direction, illustration, packaging, production, and pretty much whatever else you need me to do.
                          </p>
                          <p>
                            I’ve worked at some great spots - DDW, <br />Lab126, Ammunition, Sterling Brands, and recently, Astro Studios.
                          </p>
                          <p>
                            <strong>
                              I’d like to work for you.
                            </strong>
                          </p>
                          <Email />
                        </div>
                      </div>
                    </div>

                  { projectData.map(
                      project => <Project {...project} key={project.route} transitionFrom={transitionFrom} />
                    )
                  }
                <div className="separator" />
                <Tail />

                <Menu />
                <PageDecorations />
              </div>

          )

class Homepage extends Component {
  componentDidMount() {
    projectData.forEach(
      datum => {
        const img = new Image()
        img.src = datum.image
      }
    )
  }

  render() {
    return <HomepageContent
              />
  }
}

export default Homepage
