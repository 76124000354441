import React from 'react'
import NavArrowUp from '../NavArrow/Up';
import Email from '../Email'

import './style.css'

const SocialMedia =
        () => (
          <div className="social-media">
            <a href=" https://dribbble.com/kateelbizri" target="_blank">
              <img className="decoration-img" alt="more-arrow" src="/images/socialmedia_dribbble.svg" />
            </a>
              <a href="https://www.instagram.com/kate_elbizri/" target="_blank">

              <img className="decoration-img" alt="more-arrow" src="/images/socialmedia_instagram.svg" />
            </a>
            <a href="https://www.linkedin.com/in/kateelbizri/" target="_blank">
              <img className="decoration-img" alt="more-arrow" src="/images/socialmedia_linkedin.svg" />
            </a>
          </div>
        )
const Tail =
        () => (
          <div>
            <div className="tail">
              <h1>
                <em>
                  let’s talk.
                </em>
                <br />
                 I’d love to
                <br />
                 meet you.
              </h1>
              <br />
              <Email />
              <SocialMedia />
            </div>
            <div className='bottom-spacer' />
            <NavArrowUp />
          </div>
        )

export default Tail
