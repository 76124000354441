export default
    {
      route: `spoonful_one`
    , image: `/media/SpoonfulOne/8_website-07.jpg`
    , tagline: `no fear`
    , headlineA: `allergens`
    , headlineB: `without`
    , headlineC: `fear`
    , type: `Brand System`
    , text: `
      <p>
      SpoonfulOne is a subscription-based model for daily immunotherapy allergens for preventing food allergies in infants. Sounds scary to give your baby allergens? Our focus groups thought so too. The solution was a fun, friendly, and approachable brand expression that makes scary-sounding things less, well, scary.
      </p>
      <p>
      <em>Role: design, copywriting, illustration, strategy, packaging, design direction, UI design</em>
      </p>`
  , credits: `
      My role included design, copywriting, illustration, strategy, packaging, and UI design.
      `
    , images: [
        { title: `Introductory Kit`
          , image: `/media/SpoonfulOne/2_Welcome-Kit.jpg`
        }
      , {
        title: `Business System`
        , image: `/media/SpoonfulOne/3_Shipper.jpg`
      }
      , {
        title: `Promotional Cards`
        , image: `/media/SpoonfulOne/4_OOBE.jpg`
        , quote: `
        Phillipe Becker (ECO, Sterling Brands) “Kate sees the bigger picture and consistently delivers high-level conceptual ideas. She's an enthusiastic addition to the team, ready to take on projects big or small, and as a team lead, she sets the tone for others to achieve their own design potential.”
        `
        }
      , {
        title: `Food Packaging`
        , image: `/media/SpoonfulOne/5_Icons.jpg`
      }
      , {
        title: `Promotional Tote`
        , image: `/media/SpoonfulOne/6_Stationery.jpg`
        }
      , {
        title: `Landing Page`
        , image: `/media/SpoonfulOne/7_FoodPackaging.jpg`
        }
      , {
        title: `Landing Page`
        , image: `/media/SpoonfulOne/8_website-07.jpg`
        }
      ]
    }
