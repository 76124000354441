export default {
      title: `Concept to Completion`
    , headlineA: `concept`
    , headlineB: `to`
    , headlineC: `complete`
    , route: `lab126`
    , type: `Packaging System`
    , image: `/media/Lab126/1_HeroShot.jpg`
    , text: `
<p>
In-house allows a designer to see a project from it’s initial stages through to MP. It’s a chance to dive deep into the brand or product and see how it can evolve, and, an opportunity to take on as many hats as one wants to wear. Every detail is considered, every material scrutinized, and every quality controlled.
</p>
<p>
<em>
Role: design, art direction, photo retouching, project management, on-site press checks, CMF development and matching, MP quality control.
</em>

</p>
`
    , credits: `
    D Yours Truly<br />
`
    , images: [
        {
          title: `Kindle Fire, Kindle Fire HD`
        , image: `/media/Lab126/1_HeroShot.jpg`
        }
      , {
          title: `Next-Gen Kindle Fire HD Concept`
        , image: `/media/Lab126/2_KindleFfireConcept.jpg`
        }
      , {
          title: `Global Adapter Travel Kit`
        , image: `/media/Lab126/3_Kindle Fire Fire HD.jpg`
        }
      , {
          title: `Brand presence, Lab126`
        , image: `/media/Lab126/4_InnerBox.jpg`
      }
      , {
          title: `Company promotional materials, Lab126`
        , image: `/media/Lab126/5_KindlePowerfast.jpg`
      }
    ]
  }
