export default {
        route: `previous_work`
      , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-05.jpg`
      , title: `Previous Work`
      , headlineA: `one`
      , headlineB: `hit`
      , headlineC: `wonders`
      , text: `
      <p>
        The first half of my career was spent developing one-off packaging brand solutions. Here’s a collection of some of my favorites: some have been awarded recognition for great design, some I just genuinely think are delightful, and one has even been co-authored and published.
      </p>
      <p>
        <em>
          Role: strategy, copywriting, packaging design, design direction
        </em>
      </p>`
      , credits: `
          My role included UI and digital marketing design
  `
      , images: [
          {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-01.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-02.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-03.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-04.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-05.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-08.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-09.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-10.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/PreviousWork/CorrectSizes_Web_OneHitWonders-11.jpg`
          }

        ]
      }
