import React from 'react'

require('./style.css')


export default ( {data} ) => {

    return (
      <div className="product-animation">
        <img className="screen" src={data.screen} />
        <img className="computer" src={data.computer} />
      </div>
    )
  }
