import React from 'react'

import data from './data'

import Homepage from './pages/index'
import ItemPage from './pages/item'
import About from './pages/about'
import Resume from './pages/resume'

const routes = data.map(datum => datum.route)

const NotFound =
        () => <div>
                Page Not Found
              </div>

const getRoute =
        ( hash
        , props
          ) => {
                  //Static routes
                  switch (hash)  {
                    case '':
                      return <Homepage {...props} />;
                    case 'About':
                      return <About {...props} />;
                    case 'Resume':
                      return <Resume {...props} />;
                    default:
                      return <NotFound />
                  }
                }

const Router =
    props => {
                    const hash = props.location[0]

                    //Data routes
                    if (routes.includes(hash)) return <ItemPage {...props} route={hash} />

                    const transitionFrom =
                            (hash.endsWith('_'))
                            ? hash
                            : null

                    const route = getRoute(hash, props)

                    return route
                  }

export default Router
