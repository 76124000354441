import React, { useState } from 'react'
import Transition from 'react-transition-group/Transition';

import ItemDetail from './ItemDetail'
import ImageDisplay from './ImageDisplay'
const Project =
        props => {
          const [show, setShow] = useState(false)
          const [hide, setHide] = useState(false)
          const [hideOffset, setHideOffset] = useState(0)
          const [showHide, setShowHide]      = useState(false)

          if (props.transitionFrom) {
            const [route, offset] = props.transitionFrom.split('_')

            if (props.route === route) {
              window.history.pushState("object or string", "Title", "/#");
              if(!showHide) {
                setHideOffset(-offset)
                setShowHide(true)
              }else{
                if (!hide) {
                  setTimeout(() => {
                    setHide(true)
                  }, 0)
                }
              }
            }
          }

          const onClick =
                  () => {
                    setShow(true)
                    setTimeout(
                      () => {
                        window.location.href  = '#'+props.route
                        window.scrollTo(0,0)
                      }
                    , 1000
                    )
                  }

          return (
            <div className="project">
              <div onClick={onClick} className="item">
               <div className="product-img-holder">
                 <ImageDisplay className="product-img" alt={props.title} video={props.video} image={props.image}/>
               </div>
              </div>
              <Transition
                in={show}
                timeout={0}
              >
                {state => (
                    <div className={'detail-holder detail-show '+state}>
                      { show
                        ? (
                          <div className="detail-overlay">
                            <div className="page" >
                              <div className="height-based-constrainer">
                                <div className="overlay" >
                                <ItemDetail {...props} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      : null
                      }

                    </div>
                  )
                }
              </Transition>
              { showHide
                ? <Transition
                    in={hide}
                    timeout={0}
                    >
                    {state => {
                      const top = hideOffset + 'px'
                      return (
                      <div className={'detail-holder detail-hide '+state}>
                        {
                          show
                          ? (
                            <div className="detail-overlay" style={{top}}>
                              <div className="detail" >
                                <ItemDetail {...props} />
                              </div>
                            </div>
                          )
                          : null
                        }
                      </div>
                    )}
                  }
                  </Transition>
                : null
              }
            </div>
        )}

export default Project
