import React, { useState } from 'react'
import parse from 'html-react-parser'

import './style.css'

import Animation from '../Animation'

const Image =
        ({alt
        , src
      }) => {
        const [isLightBoxed, setLightBox] = useState(false)

        const className =
                isLightBoxed
                ? "img-box islightboxed"
                : "img-box"

        const onClick =
                () => setLightBox(!isLightBoxed)

        return (
          <div className={className} >
            <img className="product-img" alt={alt} src={src} />
          </div>
        )
      }

const ImageDisplay =
        imageInfo => (
          <div>
              { imageInfo.video && imageInfo.controls
                ? ( <div className='img-box' >
                      <video className="product-img" autoPlay controls loop muted>
                        <source src={imageInfo.video} type="video/mp4" />
                      </video>
                    </div>
                  )
                : null
              }
              { imageInfo.video && !imageInfo.controls
                ? ( <div className='img-box' >
                      <video className="product-img" autoPlay loop muted>
                        <source src={imageInfo.video} type="video/mp4" />
                      </video>
                    </div>
                  )
                : null
              }
              { imageInfo.image
                ? <Image alt={imageInfo.title} src={imageInfo.image} />
                : null
              }
              { imageInfo.animation
                ? <Animation alt={imageInfo.title} data={imageInfo.animation} />
                : null
              }
        </div>
        )

export default ImageDisplay
