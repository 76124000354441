import React from 'react'
import './style.css'

const Email =
        () => (
          <div className="email">
  <a href='&#109;ailto&#58;thi&#115;isk&#97;&#116;%&#54;5&#64;k&#97;&#116;eelbiz&#114;i&#46;%6&#52;esign'>email me.</a>
  </div>
)

export default Email
