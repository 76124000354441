import React from 'react'

import Menubar from './components/Menubar'

const About = ({data, transitionFrom}) => (
  <div className="page">
    <div className="pagecontent heropagecontent">
      <Menubar/>
      <div className="abovefold">
        <div className="hero">
          <div className="headerbox">
            <h1>
              <em>
                me
              </em>
              <br/>
                myself
              <br/>
               and I.
            </h1>
          </div>
          <div className="description">
            <p>
              I’m a dressage queen. {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'}  Currently digging the Scottish Snap {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'} John Berkow is my spirit animal {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'} Just got my first tattoo  {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'} Already thinking of the next three. {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'} I make a mean Victoria sponge. {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'} I can direct a cab driver in Mandarin, write in Arabic, sing in Italian, order food in Spanish, and count in Ukrainian, but that’s about it {'\u00A0'}{'\u00A0'}  // {'\u00A0'}{'\u00A0'} I can’t stop listening to Amon Tobin or the Pharcyde (but only their first albums).
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )

export default About
