import React from 'react'
import parse from 'html-react-parser'

import ImageDisplay from './ImageDisplay'

const ItemDetail =
        datum => (
    <div className="pagecontent">
      <div className="width-constrainer">
        <div className="hero">
          <h2>
            <em>
              {datum.headlineA}
            </em>
            <br />
            {datum.headlineB}
            <br />
            {datum.headlineC}
          </h2>
          <div className="description">

            {parse(datum.text)}
          </div>
        </div>
      </div>
      { datum.images && datum.images.map(
          (imageInfo, idx) => <ImageDisplay key={imageInfo.title+idx} {...imageInfo} />
        )
      }
    </div>
  )

export default ItemDetail
