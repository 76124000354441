export default {
        route: `roots_run_deep`
      , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-03.jpg`
      , title: `Roots Run Deep`
      , headlineA: `our`
      , headlineB: `best`
      , headlineC: `guess`
      , text: `
      <p>
         Roots Run Deep began with Educated Guess, a wine design based in wine chemistry, showing the basis for all the educated guesses winemakers have to make all year around. After the initial design won numerous accolades, including exhibition in the <a href="https://www.sfmoma.org/exhibition/how-wine-became-modern/"">SF MoMA</a>, I took over as creative director and extended the system to multiple varietal extensions, new looks, higher-end tier collections, print and marketing materials, and environmental design
      </p>
      <p>
        <em>
          Role: strategy, copywriting, packaging design, creative direction, environmental design, print design, marketing
        </em>
      </p>`
      , credits: `
          My role included UI and digital marketing design
  `
      , images: [
          {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-01.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-02.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-03.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-04.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-05.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-06.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-07.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/RootsRunDeep/CorrectSizes_RootsRunDeep-08.jpg`
          }
        ]
      }
