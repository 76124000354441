import React from 'react'
// Scroll by a component's ref
import { ScrollTo } from "react-scroll-to";
import './style.css'
import '../style.css'

const NavArrowDown =
        () => (

<ScrollTo>
  {
    ({ scroll }) => {

                        const onClick =
                                () => scroll(
                                    {
                                      smooth: true
                                    , y: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) * 0.9
                                    }
                                  )

                        return (
                          <div
                            className="more-arrow down"
                            onClick={onClick}
                            >
                            <img
                              className="more-arrow-yellow"
                              alt="more-arrow"
                              src="images/arrows-DOWN-YELLOW.svg"
                              />
                            <img
                              className="more-arrow-red"
                              alt="more-arrow"
                              src="images/arrows-DOWN.svg"
                              />
                          </div>
                        )
                      }
    }
  </ScrollTo>

)

export default NavArrowDown
