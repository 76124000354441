export default {
        route: `side_project`
      , image:  `media/SideProject/SideProject-01.jpg`
      , title: `Previous Work`
      , headlineA: `All`
      , headlineB: `my Love,`
      , headlineC: `BoB`
      , text: `
      <p>
      My grandmother was a war bride whose husband, a pilot, died while serving in World War 2. I recently came into the letters he wrote to her while he was overseas, and decided to make a book out of them. While an interesting exercise in book design, it’s also a project whose subject matter motivates me to work on weekends from time to time. My long-game is to have the book printed and bound, to give to family members and possibly publish, if I can. Shown here is a selection of inspiration ephemera from my grandfather, along with a work-in-progress spread.
      </p>
      `
      , credits: `
          My role included UI and digital marketing design
  `
      , images: [
          {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-01.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-02.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-03.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-04.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-05.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-06.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-07.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-08.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-09.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-10.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-11.jpg`
          }
        , {
            title: `Product Page`
          , image:  `media/SideProject/SideProject-12.jpg`
          }
        ]
      }
